import { css } from 'styled-components'

export const fontBold = css`
  font-family: 'Helvetica Neue LT W05 75 Bold', sans-serif;
  font-weight: 700;
  font-style: normal;
`
export const fontMedium = css`
  font-family: 'Helvetica Neue LT W05 65 Medium', sans-serif;
  font-weight: 500;
  font-style: normal;
`

export const fontRoman = css`
  font-family: 'Helvetica Neue LT W05 55 Roman', sans-serif;
  font-weight: 400;
  font-style: normal;
`
