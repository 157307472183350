import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { useEmblaCarousel } from 'embla-carousel/react'
import { useInView } from 'react-intersection-observer'
import { useRecursiveTimeout } from 'src/hooks'
import { DesktopCaption, MobileCaption } from 'src/components/Elements/Image'

const ArrowLeft = styled(require('src/images/icons/arrow-left.svg'))``

const ArrowRight = styled(require('src/images/icons/arrow-right.svg'))``

const ArrowBtn = styled.button`
  background: transparent;
  border: none;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  margin: 0;
  padding: 0;
`

export const Dots = styled.div`
  flex-shrink: 0;
  margin: 0 16px;
`

export const Dot = styled.button`
  background: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  margin: 0;
  padding: 0;

  height: 48px;
  width: 24px;
  position: relative;

  --selected: var(--purple900);
  --unselected: var(--squash300);

  &::before {
    background-color: ${({ selected }) =>
      selected ? 'var(--selected)' : 'var(--unselected)'};
    border: ${({ selected }) =>
      selected ? '2px solid var(--unselected)' : 'none'};
    border-radius: 50%;
    content: '';
    display: block;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    height: 8px;
    width: 8px;
  }
`

export const Navigation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  /* position: absolute; */
  /* bottom: 12px; */
  /* left: 50%; */
  /* transform: translate3d(-50%, 0, 0); */
`

const Container = styled.div`
  position: relative;
`

const Viewport = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  /* padding: 0 var(--padding); */
  position: relative;
  aspect-ratio: ${({ minHeight }) =>
    minHeight.mobile ? `calc( 1 / ${minHeight.mobile})` : ``};
  width: 100%;

  &.is-dragable {
    cursor: move;
    cursor: grab;
  }

  &.is-dragging {
    cursor: grabbing;
  }

  @media (min-width: 800px) {
    aspect-ratio: ${({ minHeight }) =>
      minHeight.desktop ? `calc( 1 / ${minHeight.desktop})` : ``};
  }
`

const Track = styled.div`
  display: flex;
  /* margin-left: calc(-1 * var(--padding)); */
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
`

const Slide = styled.div`
  position: relative;
  min-width: 100%;
  /* min-width: calc(18 * var(--padding)); */
  /* padding: 0 0 0 var(--padding); */
`

const Caption = styled.div`
  /* padding-bottom: 12px; */
  width: calc(100% - 96px - 1ch); /* do not overlap with nav buttons  */
`

const Footer = styled.div`
  ${({ hasDots }) =>
    hasDots
      ? `
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
  `
      : ``}

  display: flex;
  align-items: flex-start;
`

interface SliderProps {
  hasArrows?: boolean
  hasDots?: boolean
  hasCaption?: boolean
  captions?: Array<any>
  minHeight?: any
  hasAutoplay?: boolean
  autoplayInterval?: number
  settings?: any
  children: React.ReactNode
}

export default function Slider({
  hasArrows = false,
  hasDots = false,
  hasCaption = false,
  captions = [],
  minHeight = {},
  hasAutoplay = false,
  autoplayInterval = 5000,
  settings = {},
  children,
}: SliderProps) {
  const [viewportRef, embla] = useEmblaCarousel({
    align: 'center',
    containScroll: 'trimSnaps',
    loop: true,
    ...settings,
  })

  const [ref, inView] = useInView({
    threshold: 0,
  })

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([])

  const scrollTo = useCallback(index => embla && embla.scrollTo(index), [embla])
  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])

  const onSelect = useCallback(() => {
    if (!embla) return
    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
    setSelectedIndex(embla.selectedScrollSnap())
  }, [embla, setSelectedIndex])

  const autoplay = useCallback(() => {
    if (!embla) return
    if (embla.canScrollNext()) {
      embla.scrollNext()
    } else {
      embla.scrollTo(0)
    }
  }, [embla])

  const { play, stop } = useRecursiveTimeout(autoplay, autoplayInterval)

  useEffect(() => {
    if (!embla) return
    onSelect()
    setScrollSnaps(embla.scrollSnapList())
    embla.on('select', onSelect)
    embla.on('pointerDown', stop)
  }, [embla, onSelect, setScrollSnaps, stop])

  useEffect(() => {
    if (hasAutoplay && inView) {
      play()
    } else {
      stop()
    }
  }, [play, stop, inView, hasAutoplay])

  return (
    <Container ref={ref}>
      <Viewport ref={viewportRef} minHeight={minHeight}>
        <Track>
          {children?.map((child, index) => (
            <Slide key={index}>{child}</Slide>
          ))}
        </Track>
      </Viewport>
      <Footer hasDots={hasDots}>
        {hasCaption && (
          <Caption>
            <MobileCaption
              dangerouslySetInnerHTML={{
                __html:
                  captions[selectedIndex].mobile ||
                  captions[selectedIndex].desktop,
              }}
            />
            <DesktopCaption
              dangerouslySetInnerHTML={{
                __html: captions[selectedIndex].desktop,
              }}
            />
          </Caption>
        )}
        <Navigation>
          {hasArrows && prevBtnEnabled && (
            <ArrowBtn onClick={scrollPrev} aria-label="Previous Slide">
              <ArrowLeft />
            </ArrowBtn>
          )}
          {hasDots && (
            <Dots>
              {scrollSnaps.map((_, index) => (
                <Dot
                  key={index}
                  selected={index === selectedIndex}
                  onClick={() => scrollTo(index)}
                  aria-label={`Navigate to Slide ${index}`}
                />
              ))}
            </Dots>
          )}
          {hasArrows && nextBtnEnabled && (
            <ArrowBtn onClick={scrollNext} aria-label="Next Slide">
              <ArrowRight />
            </ArrowBtn>
          )}
        </Navigation>
      </Footer>
    </Container>
  )
}
