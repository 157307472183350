import styled from 'styled-components'
import { backgroundColour, boxShadow, padding } from 'src/lib/styles'
import type { Colour, Padding } from 'src/lib/styles'

interface SectionProps {
  $bgColour?: Colour
  $padding?: Padding
  $zIndex?: number
}

export const Section = styled.section<SectionProps>`
  ${backgroundColour}
  ${boxShadow}
  ${padding}
  position: relative;
  z-index: ${({ $zIndex }) => $zIndex || '2'};
`
