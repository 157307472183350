import styled from 'styled-components'
import { preset, fontBold, fontMedium } from 'src/lib/typography'
import type { Preset } from 'src/lib/typography'
import { align, colour, margin } from 'src/lib/styles'
import type { Align, Colour, Margin } from 'src/lib/styles'

interface BodyProps {
  $preset: Preset
  $align?: Align
  $colour?: Colour
  $margin?: Margin
}

export const Body = styled.p<BodyProps>`
  ${preset}
  ${align}
  ${colour}
  ${margin}

  strong {
    ${fontBold}
  }

  ul,
  ol {
    counter-reset: list;
  }

  ul > li,
  ol > li {
    list-style: none;
  }

  ul > li {
    padding-left: 2ch;
  }

  ul > li:before {
    color: var(--purple900);
    content: counter(list, disc);
    counter-increment: list;
    margin-left: -2ch;
    margin-right: 1ch;
    /* padding-left: 2ch; */
  }

  ol > li:before {
    color: var(--purple900);
    content: counter(list, decimal) ') ';
    counter-increment: list;
    ${fontMedium}
  }
`
