import { css } from 'styled-components'

export type Align = {
  desktop: string | null
  mobile: string | null
}

export const align = css<{ $align?: Align }>`
  text-align: ${({ $align }) => $align?.mobile || 'left'};

  @media (min-width: 800px) {
    text-align: ${({ $align }) => $align?.desktop || 'left'};
  }
`
