module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://colloqui.wpengine.com/graphql","verbose":true,"debug":{"preview":true,"timeBuildSteps":true,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"schema":{"perPage":10,"requestConcurrency":5,"previewRequestConcurrency":5,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000},"type":{"MediaItem":{"localFile":{"maxFileSizeBytes":209715200,"excludeByMimeTypes":[],"requestConcurrency":100},"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"}},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"custom":[{"name":["Helvetica Neue LT W05 55 Roman","Helvetica Neue LT W05 65 Medium","Helvetica Neue LT W05 75 Bold"],"file":"/fonts/helvetica.css"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"k2SlYApGvjNkRnl4ElKDWOIOqTbyxh7g","devKey":"k2SlYApGvjNkRnl4ElKDWOIOqTbyxh7g","trackPage":true,"trackPageDelay":50,"delayLoad":true,"delayLoadTime":1000,"manualLoad":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"mergeCachingHeaders":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Colloqui Interactive","short_name":"Colloqui","start_url":"/","background_color":"#4A0B64","theme_color":"#4A0B64","display":"browser","icon":"src/images/logos/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"151fcc7d85a00664b9caa9ea536e044a"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    }]
