import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  :root {
    --placeholder: #C4C4C4;
    /* Background Colours */
    --beige: #EDF2E3;
    --black: #000000;
    --white: #FFFFFF;
    /* Brand Colours */
    --purple50: #F1E3F0;
    --purple100: #DEBADA;
    --purple200: #C88DC3;
    --purple300: #B161AB;
    --purple400: #A1409A;
    --purple500: #902089;
    --purple600: #841C84;
    --purple700: #73167C;
    --purple800: #641274;
    --purple900: #4A0B64;
    --squash50: #FFF3E1;
    --squash100: #FFE0B4;
    --squash200: #FFCC83;
    --squash300: #FFB752;
    --squash400: #FFA72F;
    --squash500: #FF9814;
    --squash600: #FB8C13;
    --squash700: #F47C11;
    --squash800: #EE6D0F;
    --squash900: #E5520C;
    --turquoise50: #DCF3F0;
    --turquoise100: #A9E1D8;
    --turquoise200: #6CCEBE;
    --turquoise300: #00BAA3;
    --turquoise400: #00AA91;
    --turquoise500: #009A7E;
    --turquoise600: #008D71;
    --turquoise700: #007D61;
    --turquoise800: #006D53;
    --turquoise900: #005036;
  }

  a { 
    color: inherit;
  }

  *:active,
  *:focus {
    outline: none;
  }

`
