import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { ButtonLink, ButtonHref } from 'src/components/Elements'
import { Align, margin, Margin } from 'src/lib/styles'

const Container = styled.div<{ $align?: Align; $margin?: Margin }>`
  ${margin}

  display: flex;
  justify-content: ${({ $align }) => $align?.desktop || 'flex-start'};

  @media (min-width: 800px) {
    justify-content: ${({ $align }) => $align?.desktop || 'flex-start'};
  }
`

interface ButtonFieldsFragment {
  acfeFlexibleLayoutTitle?: string
  background: {
    colour: string
    hover: string
  }
  align: Align
  text: {
    colour: string
    content: string
  }
  kind: string
  wpInternal: {
    anchor?: string
    post: {
      __typename: 'WpPage' | 'WpPost'
      uri: string
    }
  }
  external: {
    url: string
  }
  margin: Margin
}

export default function CmsButton({ data }: { data?: ButtonFieldsFragment }) {
  return (
    <Container $align={data?.align} $margin={data?.margin}>
      {data?.kind === 'internal' && (
        <ButtonLink
          $background={data?.background}
          $colour={data?.text.colour}
          to={`${data?.wpInternal.post.uri}${
            data?.wpInternal.anchor ? `#${data?.wpInternal.anchor}` : ''
          }`}
        >
          {data?.text.content}
        </ButtonLink>
      )}
      {data?.kind === 'external' && (
        <ButtonHref
          $background={data?.background}
          $colour={data?.text.colour}
          href={data?.external.url}
        >
          {data?.text.content}
        </ButtonHref>
      )}
    </Container>
  )
}

export const fragment = graphql`
  fragment ButtonFields on WpContent_Acf_Layouts_Columns_columns_Elements_Button {
    acfeFlexibleLayoutTitle
    background {
      colour
      hover
    }
    align {
      desktop
      mobile
    }
    text {
      colour
      content
    }
    kind
    wpInternal {
      anchor
      post {
        __typename
        ... on WpPage {
          uri
        }
        ... on WpPost {
          uri
        }
      }
    }
    external {
      url
    }
    margin {
      desktop {
        direction
        unit
        value
      }
      mobile {
        direction
        unit
        value
      }
    }
  }
`
