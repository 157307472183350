import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Heading, Body } from 'src/components/Typography'
import { margin } from 'src/lib/styles'

const Container = styled.div`
  ${margin}
`

const Stat = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  align-items: flex-start;
  margin: 0 0 34px;

  &:last-child {
    margin: 0;
  }

  /*@media (min-width: 800px) {
    margin: 0 40px 0 0;

    &:last-child {
      margin: 0;
    }
  } */
`

const Figure = styled.div<{ $hasIcon?: boolean }>`
  display: flex;
  align-items: center;

  position: relative;

  &::before {
    background-color: var(--squash600);
    content: '';
    display: ${({ $hasIcon }) => ($hasIcon ? 'none' : 'block')};
    width: 4px;
    height: 82%;

    position: absolute;
    left: -16px;
    top: 10px;
  }
`

const Icon = styled.img`
  /* fill: ${({ $colour }) => $colour || 'var(--white)'}; */
  height: 28px;
  width: auto;
  margin: 0 12px 0 0;

  @media (min-width: 800px) {
    height: clamp(34px, 2.65625vw, 51px);
  }
`

export default function Stats({ data }) {
  return (
    <Container $margin={data?.margin}>
      {data?.items?.map((item, index) => (
        <Stat key={index} $margin={data?.margin}>
          <Figure $hasIcon={item?.icon}>
            <Icon
              src={item?.icon?.localFile?.publicURL}
              alt={item?.icon?.altText || 'Icon'}
            />
            <Heading
              $preset="h3"
              as="h3"
              $colour={{
                desktop: data?.colourOptions.figure,
                mobile: data?.colourOptions.figure,
              }}
              $align={{ desktop: 'left', mobile: 'left' }}
            >
              {item.figure}
            </Heading>
          </Figure>
          <Body
            $preset="bodyOne"
            $colour={{
              desktop: data?.colourOptions.description,
              mobile: data?.colourOptions.description,
            }}
          >
            {item.description}
          </Body>
        </Stat>
      ))}
    </Container>
  )
}

export const fragment = graphql`
  fragment StatsFields on WpContent_Acf_Layouts_Columns_columns_Elements_Stats {
    acfeFlexibleLayoutTitle
    margin {
      desktop {
        direction
        unit
        value
      }
      mobile {
        direction
        unit
        value
      }
    }
    colourOptions {
      figure
      description
    }
    items {
      icon {
        altText
        localFile {
          relativePath
          publicURL
        }
      }
      figure
      description
    }
  }
`
