import React from 'react'
import styled from 'styled-components'
import { Section, Divider } from 'src/components/Layout'
import { Image, BackgroundImage } from 'src/components/Elements'
import type { Source } from 'src/components/Elements/Image'
import type { Colour, Padding } from 'src/lib/styles'

const FeaturedBg = styled.div`
  position: relative;
  transform: translateX(40%);

  @media (min-width: 800px) {
    position: absolute;

    right: -28%;
    top: 50%;
    transform: translateY(-50%);
  }
`

const MaskWingSvg = styled(require('src/images/shapes/bird-wing-clip.svg'))`
  position: absolute;
  height: 0;
  width: 0;
`

const MaskWing = styled.div`
  position: absolute;
  left: -10%;
  top: 0;

  .gatsby-image-wrapper {
    clip-path: url(#bird-wing-clip);
    height: clamp(237px, 63.136vw, 505px);
    width: clamp(368px, 98.13334vw, 785px);

    @media (min-width: 800px) {
      height: clamp(116px, 30.9375vw, 594px);
      width: clamp(180px, 47.971875vw, 921px);
    }
  }
`

const MaskBody = styled(require('src/images/shapes/bird-body.svg'))`
  height: auto;
  width: clamp(482px, 128.512vw, 1028px);

  @media (min-width: 800px) {
    width: clamp(235px, 62.75vw, 1205px);
  }
`

const Content = styled.div<{ $kind?: string }>`
  position: ${({ $kind }) => ($kind === 'image' ? 'absolute' : 'relative')};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

type Background = {
  kind?: string
  colour?: Colour
  image?: Source
  divider?: string
}

interface ContainerProps {
  anchor?: string
  background?: Background
  children?: React.ReactNode
  className?: string
  featured?: boolean
  padding?: Padding
  top?: boolean
  zIndex?: number
}

export default function Container({
  anchor,
  background,
  children,
  className,
  featured = false,
  padding,
  top,
  zIndex,
}: ContainerProps) {
  return (
    <>
      <Section
        id={anchor}
        className={className}
        $bgColour={background?.colour}
        $padding={padding}
        $zIndex={zIndex}
      >
        {!featured && (
          <>
            {background?.kind === 'image' && (
              <BackgroundImage
                image={background?.image}
                imgProps={{ loading: top ? 'eager' : 'lazy' }}
                hasFilter={background?.hasFilter}
              />
            )}
            <Content $kind={background?.kind}>{children}</Content>
          </>
        )}
        {featured && (
          <>
            {children}
            <FeaturedBg>
              <MaskBody />
              <MaskWingSvg />
              <MaskWing>
                <Image
                  source={background?.image}
                  loading={top ? 'eager' : 'lazy'}
                />
              </MaskWing>
            </FeaturedBg>
          </>
        )}
      </Section>
      {background?.divider !== 'flat' && (
        <Divider
          type={background?.divider}
          bgColour={background?.colour}
          zIndex={zIndex}
        />
      )}
    </>
  )
}
