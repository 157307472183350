import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { Heading } from 'src/components/Typography'
import { LinkStyledAsButton } from 'src/components/Elements'
import { trackDirect } from 'src/lib/analytics/segment'

const Container = styled.div`
  background-color: var(--beige);
  border: none;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 80px 0 0;
  padding: 140px 36px 42px;

  position: relative;

  z-index: 20;
`

const ImageWrapper = styled.div`
  position: absolute;
  top: -10%;
  left: 50%;
  transform: translate3d(-50%, 0, 0);

  border-radius: 50%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  overflow: hidden;
`

export default function CEO(props) {
  return (
    <Container {...props}>
      <ImageWrapper>
        <StaticImage
          src={'../../images/mockups/robin-kerbel.jpg'}
          layout="fixed"
          width={150}
          height={150}
          placeholder="none"
          loading="eager"
          backgroundColor="#fefefe"
          alt="Robin Kerbel"
        />
      </ImageWrapper>
      <Heading
        $preset="h5"
        as="h3"
        $colour={{ desktop: 'var(--purple900)', mobile: 'var(--purple900)' }}
        $align={{ desktop: 'center', mobile: 'center' }}
        css={`
          margin: 0 0 16px;
        `}
      >
        Email our CEO directly
      </Heading>
      <LinkStyledAsButton
        to="mailto:robin@colloqui-interactive.com"
        onClick={() => trackDirect()}
      >
        send direct email
      </LinkStyledAsButton>
    </Container>
  )
}
