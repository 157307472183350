import styled from 'styled-components'
import { preset } from 'src/lib/typography'
import type { Preset } from 'src/lib/typography'
import { align, colour, margin } from 'src/lib/styles'
import type { Align, Colour, Margin } from 'src/lib/styles'

interface HeadingProps {
  $preset: Preset
  $align?: Align
  $colour?: Colour
  $margin?: Margin
}

export const Heading = styled.span<HeadingProps>`
  ${preset}
  ${align}
  ${colour}
  ${margin}
`
