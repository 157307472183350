import { css } from 'styled-components'
import {
  fontBold,
  fontMedium,
  fontRoman,
  sizeH1,
  sizeH2,
  sizeH3,
  sizeH4,
  sizeH5,
  sizeH6,
  sizeBodyLarge,
  sizeBodyOne,
  sizeBodyTwo,
} from 'src/lib/typography'

export const h1 = css`
  ${fontBold}
  ${sizeH1}
`

export const h2 = css`
  ${fontBold}
  ${sizeH2}
`

export const h3 = css`
  ${fontBold}
  ${sizeH3}
`

export const h4 = css`
  ${fontMedium}
  ${sizeH4}
`

export const h5 = css`
  ${fontMedium}
  ${sizeH5}
`

export const h6 = css`
  ${fontMedium}
  ${sizeH6}
`

export const bodyLarge = css`
  ${fontRoman}
  ${sizeBodyLarge}
`

export const bodyOne = css`
  ${fontRoman}
  ${sizeBodyOne}
`

export const bodyTwo = css`
  ${fontRoman}
  ${sizeBodyTwo}
`

export const blogCategory = css`
  ${fontBold}
  ${sizeBodyTwo}
`

export type Preset =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'bodyLarge'
  | 'bodyOne'
  | 'bodyTwo'
  | 'blogCategory'
  | null

function selectPreset(preset: Preset) {
  switch (preset) {
    case `h1`:
      return h1
    case `h2`:
      return h2
    case `h3`:
      return h3
    case `h4`:
      return h4
    case `h5`:
      return h5
    case `h6`:
      return h6
    case `bodyLarge`:
      return bodyLarge
    case `bodyOne`:
      return bodyOne
    case `bodyTwo`:
      return bodyTwo
    case `blogCategory`:
      return blogCategory
    default:
      throw new Error(`[Error] Unhandled heading font preset ${preset}`)
  }
}

export const preset = css<{ $preset: Preset }>`
  ${({ $preset }) => $preset && selectPreset($preset)}
`
