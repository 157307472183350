import { DefaultTheme } from 'styled-components'

export const defaultTheme: DefaultTheme = {
  breakpoints: {
    xs: 0,
    sm: 600,
    md: 768,
    lg: 1024,
    xl: 1280,
  },
}
