import styled from 'styled-components'
import { motion } from 'framer-motion'
import { fontMedium } from 'src/lib/typography'

export const ButtonStyledAsText = styled.button`
  border: none;
  background-color: transparent;
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  margin: 0 1ch;
  padding: 0;
`

interface ButtonProps {
  // preset?: 'primary' | 'secondary' | null | undefined
  colour?: string | null | undefined
  bgColour?: string | null | undefined
}

export const buttonVariants = {
  normal: {
    backgroundPositionX: '0%',
    transition: {
      type: 'tween',
      duration: 0.5,
    },
  },
  hover: {
    backgroundPositionX: '100%',
    transition: {
      type: 'tween',
      duration: 1,
    },
  },
  tap: {
    backgroundPositionX: '100%',
    transition: {
      type: 'tween',
      duration: 0.25,
      ease: 'linear',
    },
  },
}

export const ButtonStyledAsButton = styled(motion.button).attrs(props => ({
  animate: 'normal',
  whileHover: 'hover',
  whileTap: 'tap',
  variants: buttonVariants,
}))<ButtonProps>`
  background: linear-gradient(
    75deg,
    rgba(0, 186, 163, 1) 0%,
    rgba(0, 186, 163, 1) 33%,
    rgba(161, 64, 154, 1) 67%,
    rgba(161, 64, 154, 1) 100%
  );
  background-size: 300%;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  ${fontMedium}
  font-size: 14px;
  line-height: 28px;
  padding: 8px 38px;

  @media (min-width: 800px) {
    border-radius: 24px;
    font-size: 18px;
  }
`
