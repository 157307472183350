import React, { useRef, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { useMediaQuery } from 'src/hooks'

import { margin } from 'src/lib/styles'
import type { Margin } from 'src/lib/styles'

type ContainerProps = {
  $margin?: Margin
}

const Container = styled.div<ContainerProps>`
  ${margin}
`

const Content = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  /* margin: 0 0 30px; */
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

type PlaceholderProps = {
  $aspectRatio: number
  query?: string
}

const Placeholder = styled.div<PlaceholderProps>`
  /* background-color: #dedede; */
  display: block;
  margin-bottom: -1px; // Fix for padding-top spacing
  padding-top: ${({ $aspectRatio }) => `${$aspectRatio * 100}%`};
  position: relative;
  width: 100%;
`

const MobilePlaceholder = styled(Placeholder)`
  ${({ query }) => `
    @media ${query} {
      display: none;
    }
  `}
`

const DesktopPlaceholder = styled(Placeholder)`
  display: none;
  ${({ query }) => `
    @media ${query} {
      display: block;
    }
  `}
`

const Player = styled.video`
  /* Formatting */
  /* background-color: #dedede; */
  cursor: pointer;
  display: block; /* Required to elimininate 4px gap on bottom from inline element */
  object-fit: cover;
  z-index: 0;
  /* Sizing */
  height: auto;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

export function Video({ data }) {
  const [isLoaded, setIsLoaded] = useState(false)
  const { ref, inView } = useInView()

  const hasMobileSrc = !!data?.video?.media?.mobile
  const mobileAspectRatio =
    data?.video.media.mobile?.mediaDetails.height /
    data?.video.media.mobile?.mediaDetails.width
  const desktopAspectRatio =
    data?.video.media.desktop?.mediaDetails.height /
    data?.video.media.desktop?.mediaDetails.width

  const isDesktop = useMediaQuery('(min-width: 800px)')

  useEffect(() => {
    if (inView) {
      setIsLoaded(true)
    }
  }, [inView])

  return (
    <Container $margin={data?.margin} ref={ref}>
      <MobilePlaceholder
        $aspectRatio={hasMobileSrc ? mobileAspectRatio : desktopAspectRatio}
        query={'(min-width: 800px)'}
      >
        {!isDesktop && (
          <Player
            {...data?.video?.options?.reduce((acc, curr) => {
              acc[curr] = true
              return acc
            }, {})}
            playsInline
            preload="none"
            poster={
              data?.video?.poster?.mobile?.localFile.childImageSharp.poster
                .src ||
              data?.video?.poster?.desktop?.localFile.childImageSharp.poster.src
            }
          >
            {isLoaded && (
              <source
                src={data?.video?.media?.mobile?.localFile.publicURL}
                type={data?.video?.media?.mobile?.mimeType}
              />
            )}
            {!data?.video?.media?.mobile && isLoaded && (
              <source
                src={data?.video?.media?.desktop?.localFile?.publicURL}
                type={data?.video?.media?.mimeType}
              />
            )}
          </Player>
        )}
      </MobilePlaceholder>
      <DesktopPlaceholder
        $aspectRatio={desktopAspectRatio}
        query={'(min-width: 800px)'}
      >
        {isDesktop && (
          <Player
            {...data?.video?.options?.reduce((acc, curr) => {
              acc[curr] = true
              return acc
            }, {})}
            playsInline
            preload="none"
            poster={
              data?.video?.poster?.desktop?.localFile.childImageSharp.poster.src
            }
          >
            {isLoaded && (
              <source
                src={data?.video?.media?.desktop?.localFile?.publicURL}
                type={data?.video?.media?.desktop?.mimeType}
              />
            )}
          </Player>
        )}
      </DesktopPlaceholder>
    </Container>
  )
}

export const sourceFragment = graphql`
  fragment MediaFields on WpMediaItem {
    mimeType
    mediaDetails {
      height
      width
    }
    mediaItemUrl
    localFile {
      publicURL
    }
  }
`

export const posterFragment = graphql`
  fragment PosterFields on WpMediaItem {
    mediaDetails {
      height
      width
    }
    localFile {
      childImageSharp {
        poster: resize(width: 1280, quality: 90) {
          src
        }
      }
    }
  }
`

export const fragments = graphql`
  fragment VideoFields on WpContent_Acf_Layouts_Columns_columns_Elements_Video {
    margin {
      desktop {
        direction
        unit
        value
      }
      mobile {
        direction
        unit
        value
      }
    }
    video {
      media {
        desktop {
          ...MediaFields
        }
        mobile {
          ...MediaFields
        }
      }
      poster {
        desktop {
          ...PosterFields
        }
        mobile {
          ...PosterFields
        }
      }
      options
    }
  }
`
