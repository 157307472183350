import { css } from 'styled-components'

export type Colour = {
  desktop?: string
  mobile?: string
}

export const colour = css<{ $colour?: Colour }>`
  color: ${({ $colour }) => $colour?.mobile || 'var(--black)'};

  @media (min-width: 800px) {
    color: ${({ $colour }) => $colour?.desktop || 'var(--black)'};
  }
`

export const backgroundColour = css<{ $bgColour?: Colour }>`
  background-color: ${({ $bgColour }) => $bgColour?.mobile || 'var(--white)'};

  @media (min-width: 800px) {
    background-color: ${({ $bgColour }) =>
      $bgColour?.desktop || 'var(--white)'};
  }
`

export const boxShadow = css<{ $bgColour?: Colour }>`
  box-shadow: ${({ $bgColour }) =>
    $bgColour?.mobile ? `0 1px ${$bgColour.mobile}` : 'none'};

  @media (min-width: 800px) {
    box-shadow: ${({ $bgColour }) =>
      $bgColour?.desktop ? `0 1px ${$bgColour.desktop}` : 'none'};
  }
`
