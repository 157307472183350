import { css } from 'styled-components'

type Direction = string | undefined

type MarginRow = {
  direction?: ReadonlyArray<Direction>
  unit?: string
  value?: number
}

export type Margin = {
  desktop?: ReadonlyArray<MarginRow | undefined>
  mobile?: ReadonlyArray<MarginRow | undefined>
}

export const margin = css<{ $margin?: Margin }>`
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;

  ${({ $margin }) =>
    Array.isArray($margin?.mobile)
      ? $margin?.mobile.map(row =>
          row.direction.map(
            (dir: Direction) =>
              dir && `margin-${dir}: ${row.value}${row.unit}; `
          )
        )
      : 'margin: 0;'}

  @media (min-width: 800px) {
    ${({ $margin }) =>
      Array.isArray($margin?.desktop)
        ? $margin?.desktop.map(row =>
            row.direction.map(
              (dir: Direction) =>
                dir && `margin-${dir}: ${row.value}${row.unit}; `
            )
          )
        : 'margin: 0;'}
  }
`
