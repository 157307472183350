import React from 'react'
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { fontMedium } from 'src/lib/typography'
import { buttonVariants } from 'src/components/Elements'

const button = css<{
  $colour: string
  $background: { colour: string; hover: string }
}>`
  background: ${({ $background }) => `linear-gradient(
    75deg,
    ${$background?.colour || 'rgba(0, 186, 163, 1)'} 0%,
    ${$background?.colour || 'rgba(0, 186, 163, 1)'} 33%,
    ${$background?.hover || 'rgba(161, 64, 154, 1)'} 67%,
    ${$background?.hover || 'rgba(161, 64, 154, 1)'} 100%
  )`};
  background-size: 300%;
  border-radius: 20px;
  color: ${({ $colour }) => $colour || 'var(--purple900)'};
  cursor: pointer;
  font-size: 14px;
  line-height: 28px;
  padding: 8px 38px;
  text-decoration: none;
  white-space: nowrap;

  @media (min-width: 800px) {
    border-radius: clamp(24px, 1.875vw, 36px);
    font-size: clamp(18px, 1.40625vw, 27px);
    line-height: clamp(28px, 2.1875vw, 42px);
    padding: clamp(12px, 0.9375vw, 18px) clamp(40px, 3.125vw, 60px);
  }
`

const TextLink = styled(GatsbyLink)`
  color: inherit;
  text-decoration: underline;
`

export const HeaderLink = styled(GatsbyLink)`
  color: inherit;
  display: block;
  position: relative;
  text-decoration: none;

  &::after {
    background-color: var(--squash300);
    content: '';
    display: block;
    opacity: 0;
    transition: opacity 0.25s linear, width 0.5s ease-in;

    position: absolute;
    bottom: -12px;
    left: 0;
    width: 0;
    height: 5px;
  }

  &.active::after {
    opacity: 1;
    width: 100%;
  }

  &:hover::after {
    opacity: 1;
    width: 100%;
  }
`

const TextHref = styled.a`
  color: inherit;
  text-decoration: underline;
`

export const ButtonLink = styled(motion(GatsbyLink)).attrs(props => ({
  animate: 'normal',
  whileHover: 'hover',
  variants: buttonVariants,
}))`
  ${button}
  ${fontMedium}
`

export const ButtonHref = styled(motion.a).attrs(props => ({
  animate: 'normal',
  whileHover: 'hover',
  variants: buttonVariants,
}))`
  ${button}
  ${fontMedium}
`

type CustomGatsbyLinkProps = Omit<
  GatsbyLinkProps<Record<string, unknown>>,
  'ref'
>

export function UnstyledLink({
  children,
  to,
  activeClassName,
  partiallyActive,
  ...rest
}: CustomGatsbyLinkProps) {
  const internal = /^\/(?!\/)/.test(to)

  if (internal)
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...rest}
        css={`
          text-decoration: none;
        `}
      >
        {children}
      </GatsbyLink>
    )

  return (
    <a
      href={to}
      target="_blank"
      rel="noreferrer noopener"
      css={`
        text-decoration: none;
      `}
      {...rest}
    >
      {children}
    </a>
  )
}

export function LinkStyledAsText({
  children,
  to,
  activeClassName,
  partiallyActive,
  ...rest
}: CustomGatsbyLinkProps) {
  const internal = /^\/(?!\/)/.test(to)

  if (internal)
    return (
      <TextLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...rest}
      >
        {children}
      </TextLink>
    )

  return (
    <TextHref href={to} target="_blank" rel="noreferrer noopener" {...rest}>
      {children}
    </TextHref>
  )
}

export function LinkStyledAsButton({
  children,
  to,
  activeClassName,
  partiallyActive,
  ...rest
}: CustomGatsbyLinkProps) {
  const internal = /^\/(?!\/)/.test(to)

  if (internal)
    return (
      <ButtonLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...rest}
      >
        {children}
      </ButtonLink>
    )

  return (
    <ButtonHref href={to} target="_blank" rel="noreferrer noopener" {...rest}>
      {children}
    </ButtonHref>
  )
}
