import React from 'react'
import styled from 'styled-components'
import { ColumnGrid } from 'src/components/Layout'
import { LinkStyledAsButton } from 'src/components/Elements'
import { Heading, Body } from 'src/components/Typography'

const ConnectSection = styled.section`
  background-color: transparent;
  position: relative;
  z-index: 20;
  margin: -100px 0 0;
`

const ConnectContainer = styled.div`
  grid-column: 1 / -1;

  background-color: var(--white);
  border: none;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 40px 55px;

  @media (min-width: 800px) {
    grid-column: 2 / 12;
    grid-row: 1 / 2;

    flex-direction: row;
    align-items: flex-start;

    padding: 60px 100px;
    /* padding: clamp(60px, 4.6875vw, 90px) clamp(100px, 7.8125vw, 150px); */
  }
`

export default function FooterCTA() {
  return (
    <ConnectSection>
      <ColumnGrid>
        <ConnectContainer>
          <div
            css={`
              display: flex;
              flex-direction: column;
              align-items: center;
              margin: 0 0 20px;

              @media (min-width: 800px) {
                align-items: flex-start;
                margin: 0 20px 0 0;
              }
            `}
          >
            <Heading
              $preset="h3"
              as="h3"
              $colour={{ desktop: 'var(--purple900)', mobile: 'var(--purple)' }}
              $align={{ desktop: 'left', mobile: 'center' }}
              css={`
                margin: 0 0 16px;
              `}
            >
              Want to interact?
            </Heading>
            <Body
              $preset="bodyLarge"
              $colour={{ desktop: 'var(--black)', mobile: 'var(--black)' }}
              $align={{ desktop: 'left', mobile: 'center' }}
            >
              Get in touch today to learn more about how we can help you connect
              with your audience.
            </Body>
          </div>
          <LinkStyledAsButton to="/lets-connect">
            let's connect
          </LinkStyledAsButton>
        </ConnectContainer>
      </ColumnGrid>
    </ConnectSection>
  )
}
