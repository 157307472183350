import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Slider, Image } from 'src/components/Elements'
import { DesktopCaption, MobileCaption } from 'src/components/Elements/Image'
import { Navigation } from 'src/components/Elements/Slider'
import { margin, borderRadius } from 'src/lib/styles'

const Container = styled.div`
  ${margin}

  ${Navigation} {
    bottom: 0;
    right: 0;
    left: unset;

    transform: unset;
  }

  .gatsby-image-wrapper,
  .gatsby-image-wrapper img {
    ${borderRadius}
  }
`

export default function Gallery({ data }) {
  const captions = data?.gallery?.reduce(
    (acc, curr) => [
      ...acc,
      {
        desktop: curr.image?.desktop?.caption,
        mobile: curr.image?.mobile?.caption,
      },
    ],
    []
  )

  const minHeights = data.gallery.reduce((acc, curr) => {
    return [
      ...acc,
      {
        desktop:
          curr.image?.desktop?.localFile?.childImageSharp.gatsbyImageData
            .height,
        mobile:
          curr.image?.mobile?.localFile?.childImageSharp.gatsbyImageData.height,
      },
    ]
  }, [])

  const minHeight = {
    desktop: minHeights.reduce((acc, curr) => {
      return curr.desktop > acc ? curr.desktop : acc
    }, 0),
    mobile: minHeights.reduce((acc, curr) => {
      return curr.mobile > acc ? curr.mobile : acc
    }, 0),
  }

  return (
    <Container $margin={data?.margin} $borderRadius={data?.borderRadius}>
      <Slider hasArrows hasCaption captions={captions} minHeight={minHeight}>
        {data?.gallery.map((slide, index) => (
          <Image key={index} source={slide.image} caption={false} />
        ))}
      </Slider>
    </Container>
  )
}

export const fragment = graphql`
  fragment GalleryFields on WpContent_Acf_Layouts_Columns_columns_Elements_Gallery {
    gallery {
      image {
        desktop {
          ...DesktopImageFields
        }
        mobile {
          ...MobileImageFields
        }
      }
    }
    borderRadius {
      desktop {
        direction
        unit
        value
      }
      mobile {
        direction
        unit
        value
      }
    }
    margin {
      desktop {
        direction
        unit
        value
      }
      mobile {
        direction
        unit
        value
      }
    }
  }
`
