import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { UnstyledLink } from 'src/components/Elements'
import { fontMedium } from 'src/lib/typography'

const LogoBird = styled(require('src/images/logos/colloqui-bird.svg'))`
  height: 57px;
  width: 93px;
`

const Drawer = styled(motion.div)`
  background-color: var(--purple900);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  /* clip-path: path(
    'M0 0H1280V185.185C1280 185.185 1080 200 640 200C200 200 0 185.185 0 185.185V0Z'
  ); */
  z-index: 1000;
`

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: absolute;
  top: 30%;
  left: 50%;
  height: auto;
  width: 100%;
  transform: translate3d(-50%, -30%, 0);
`

const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  margin: 48px 0 0;
  padding: 0;
`

const NavItem = styled(motion.li)`
  margin: 0 0 32px;
`

const NavItemText = styled.h2`
  color: var(--squash300);
  ${fontMedium}
  font-size: 24px;
  line-height: 28px;
`

const drawerVariants = {
  closed: {
    height: 0,
    transition: {
      delay: 0.5,
      duration: 0.5,
      staggerDirection: -1,
      staggerChildren: 0.1,
    },
  },
  open: {
    height: '100vh',
    transition: { duration: 0.75, delayChildren: 0.5, staggerChildren: 0.1 },
  },
}

const itemVariants = {
  closed: {
    opacity: 0,
    y: '100%',
    transition: { duration: 0.25 },
  },
  open: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.25 },
  },
}

// interface NavDrawerProps {
//   menuItems: Array<{
//     name: string
//     uri: string
//   }>
// }

export default function NavDrawer({ menuItems }) {
  return (
    <Drawer
      initial="closed"
      animate="open"
      exit="closed"
      variants={drawerVariants}
    >
      <Nav>
        <LogoBird />
        <NavList>
          <NavItem variants={itemVariants}>
            <UnstyledLink to="/">
              <NavItemText>Home</NavItemText>
            </UnstyledLink>
          </NavItem>
          {menuItems.map((item, index) => (
            <NavItem key={index} variants={itemVariants}>
              <UnstyledLink to={item.url}>
                <NavItemText>{item.label}</NavItemText>
              </UnstyledLink>
            </NavItem>
          ))}
        </NavList>
      </Nav>
    </Drawer>
  )
}
