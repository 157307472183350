import styled from 'styled-components'
import { vwRatio, maxRatio } from 'src/lib/styles'

export const Grid = styled.div`
  display: grid;
  grid-template-areas: 'left content right';
  grid-template-columns: clamp(20px, ${40 * vwRatio}vw, ${40 * maxRatio}px) 1fr clamp(
      20px,
      ${40 * vwRatio}vw,
      ${40 * maxRatio}px
    );

  & > * {
    grid-area: content;
  }
`

interface ColumnGridProps {
  $bleed?: Array<string | undefined>
}

export const ColumnGrid = styled.div<ColumnGridProps>`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 16px;
  /* margin: 0 32px; */
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  ${({ $bleed }) =>
    Array.isArray($bleed) && $bleed.map(dir => dir && `margin-${dir}: 32px;`)}

  @media (min-width: 800px) {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: clamp(20px, 1.5625vw, 40px);
    /* margin: 0 clamp(40px, 3.125vw, 80px); */
    ${({ $bleed }) =>
      Array.isArray($bleed)
        ? $bleed.map(dir => dir && `margin-${dir}: clamp(40px, 3.125vw, 80px);`)
        : 'margin: 0;'}
  }
`

ColumnGrid.defaultProps = {
  $bleed: ['left', 'right'],
}
