import React from 'react'
import { graphql } from 'gatsby'
import { Heading, Body } from 'src/components/Typography'
import type { Preset } from 'src/lib/typography'
import type { Align, Colour, Margin } from 'src/lib/styles'

interface PreformattedTextProps {
  data: {
    acfeFlexibleLayoutTitle: string
    align: Align
    colour: Colour
    content: string
    element: Preset
    as: 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'blockquote'
    margin: Margin
  }
}

export function PreformattedText({ data }: PreformattedTextProps) {
  if (data?.element.match(/^h1|h2|h3|h4|h5|h6$/))
    return (
      <Heading
        $preset={data.element}
        as={data.as === 'p' ? 'div' : data.as}
        $colour={data.colour}
        $align={data.align}
        $margin={data.margin}
        dangerouslySetInnerHTML={{ __html: data.content }}
      />
    )
  else if (data?.element.match(/^bodyLarge|bodyOne|bodyTwo$/)) {
    return (
      <Body
        $preset={data.element}
        as={data.as === 'p' ? 'div' : data.as}
        $colour={data.colour}
        $align={data.align}
        $margin={data.margin}
        dangerouslySetInnerHTML={{ __html: data.content }}
      />
    )
  } else throw new Error('[Error] Unhandled element')
}

export const fragment = graphql`
  fragment PreformattedTextFields on WpContent_Acf_Layouts_Columns_columns_Elements_PreformattedText {
    acfeFlexibleLayoutTitle
    align {
      desktop
      mobile
    }
    colour {
      desktop
      mobile
    }
    content
    element
    as
    margin {
      desktop {
        direction
        unit
        value
      }
      mobile {
        direction
        unit
        value
      }
    }
  }
`
