import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'src/hooks'
import { globalHistory } from '@reach/router'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { LinkStyledAsText, UnstyledLink } from 'src/components/Elements'
import { fontMedium, fontRoman } from 'src/lib/typography'
import { trackDirect } from 'src/lib/analytics/segment'

const NavHeading = styled.button`
  border: none;
  background-color: transparent;
  color: var(--white);
  cursor: pointer;
  display: flex;
  align-items: center;
  ${fontMedium}
  font-size: 18px;
  line-height: 21px;
  padding: 0;
  text-align: center;

  @media (min-width: 1280px) {
    cursor: auto;
    font-size: clamp(18px, 1.40625vw, 27px);
    line-height: clamp(21px, 1.640625vw, 32px);
    text-align: left;
  }
`

const Chevron = styled(require('src/images/icons/chevron.svg'))`
  margin: 0 0 0 9px;
  transform: ${({ open }) => (open ? `rotate(180deg)` : `rotate(0deg)`)};
  transition: transform 0.5s;

  @media (min-width: 1280px) {
    display: none;
  }
`

const NavList = styled(motion.ul)`
  display: flex;
  flex-direction: column;
  align-items: center;

  list-style: none;
  margin: 24px 0 0;
  padding: 0;

  @media (min-width: 1280px) {
    align-items: flex-start;
  }
`
const NavListItem = styled(motion.li)``

const NavListItemText = styled.span`
  color: var(--squash300);

  grid-column: 2 / 3;

  ${fontRoman}
  font-size: 16px;
  line-height: 32px;

  text-align: center;

  @media (min-width: 1280px) {
    font-size: clamp(16px, 1.25vw, 24px);
    line-height: clamp(32px, 2.5vw, 48px);

    text-align: left;
  }
`

const listVariants = {
  open: {
    height: 'auto',
    transition: {
      duration: 0.5,
      staggerChildren: 0.1,
      delayChildren: 0.2,
    },
  },
  closed: {
    height: 0,
    transition: {
      duration: 0.5,
      staggerDirection: -1,
      staggerChildren: 0.1,
    },
  },
}

const listItemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.1,
    },
  },
  closed: {
    opacity: 0,
    y: '100%',
    transition: {
      duration: 0.1,
    },
  },
}

// interface AccordionProps {
//   list: {
//     heading: string
//     uri: string
//     links: Array<{ name: JSX.Element | string; uri: string }> | null
//   }
// }

export default function NavAccordion({ heading, url, links }) {
  const [open, setOpen] = useState(false)
  const isDesktop = useMediaQuery('(min-width: 1280px)')

  useEffect(() => {
    globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        !isDesktop && setOpen(false)
      }
    })

    if (isDesktop) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [isDesktop, setOpen])

  return (
    <>
      <NavHeading
        disabled={isDesktop}
        onClick={() => setOpen(prevState => !prevState)}
      >
        {url === '#' ? (
          `${heading}`
        ) : (
          <UnstyledLink to={url !== '#' ? url : null}>{heading}</UnstyledLink>
        )}
        <Chevron open={open} />
      </NavHeading>
      <AnimatePresence>
        {open && (
          <NavList
            initial="closed"
            animate="open"
            exit="closed"
            variants={listVariants}
          >
            {links?.map((link, index) => (
              <NavListItem key={index} variants={listItemVariants}>
                <LinkStyledAsText
                  to={link.url}
                  css={
                    link?.menuIcons?.icon
                      ? `
                    display: grid;
                    grid-template-columns: 20px 1fr;
                    grid-gap: 12px;
                    place-items: center center;
                    
                  `
                      : `text-decoration: none;`
                  }
                  onClick={link.onClick}
                >
                  {link?.menuIcons?.icon ? (
                    <img
                      style={{ display: 'inline-block' }}
                      src={link.menuIcons.icon.localFile.publicURL}
                    />
                  ) : null}
                  <NavListItemText>{link.label}</NavListItemText>
                </LinkStyledAsText>
              </NavListItem>
            ))}
          </NavList>
        )}
      </AnimatePresence>
    </>
  )
}
