import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { margin } from 'src/lib/styles'

import { Heading, Body } from 'src/components/Typography'

const Container = styled.ul`
  list-style: none;
  padding: 0;
  ${margin}
`

const ListItem = styled.li`
  margin: 0 0 32px;
`

const ListHeading = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 32px;

  position: relative;

  ${({ $hasIcon }) =>
    !$hasIcon
      ? `
      padding-left: 16px;

      &::before {
          background-color: var(--turquoise300);
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 4px;
        }`
      : null}
`

const ListBody = styled.div``

const Icon = styled.div<{ icon: string | null | undefined }>`
  background-color: var(--purple400);
  background-image: ${({ icon }) => `url(${icon})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px auto;
  border-radius: 0 0 12px 0;
  margin: 0 20px 0 0;
  height: 40px;
  width: 40px;

  @media (min-width: 800px) {
    background-size: 32px auto;
    border-radius: 0 0 24px 0;

    height: 60px;
    width: 60px;
  }
`

const NestedList = styled.ul`
  color: var(--squash300);
  list-style: disc;
`

const NestedListItem = styled.li`
  margin: 0 0 16px;
`

export function List({ data }) {
  return (
    <Container>
      {data.list.map((item, index) => (
        <ListItem key={index}>
          <ListHeading $hasIcon={!!item.icon} $colour={item.colour}>
            {item.icon && <Icon icon={item.icon?.localFile.publicURL} />}
            <Heading
              $preset={item.icon && data.list.length <= 1 ? 'h4' : 'h5'}
              as="span"
              $colour={item.colour}
              dangerouslySetInnerHTML={{ __html: item.heading }}
            />
          </ListHeading>
          {item.items && (
            <ListBody>
              <NestedList>
                {item.items.map((nestedItem, index) => (
                  <NestedListItem key={index}>
                    <Body
                      $preset="bodyOne"
                      as="span"
                      $colour={nestedItem.colour}
                      dangerouslySetInnerHTML={{ __html: nestedItem.content }}
                    />
                  </NestedListItem>
                ))}
              </NestedList>
            </ListBody>
          )}
        </ListItem>
      ))}
    </Container>
  )
}

export const fragment = graphql`
  fragment ListFields on WpContent_Acf_Layouts_Columns_columns_Elements_List {
    acfeFlexibleLayoutTitle
    list {
      icon {
        altText
        localFile {
          relativePath
          publicURL
        }
      }
      heading
      colour {
        desktop
        mobile
      }
      items {
        content
        colour {
          desktop
          mobile
        }
      }
    }
    margin {
      desktop {
        direction
        unit
        value
      }
      mobile {
        direction
        unit
        value
      }
    }
  }
`
