import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useMediaQuery } from 'src/hooks'
import { globalHistory } from '@reach/router'
import styled from 'styled-components'
import { AnimatePresence } from 'framer-motion'
import { Grid } from 'src/components/Layout'
import { UnstyledLink, HeaderLink } from 'src/components/Elements'
import { Heading } from 'src/components/Typography'
import { NavDrawer } from 'src/components/Navigation'

const Container = styled.header`
  background-color: ${({ $opaque }) =>
    $opaque ? 'var(--purple900)' : 'transparent'};
  box-shadow: ${({ $opaque }) =>
    $opaque ? '0 -1px var(--purple900)' : 'none'};
  z-index: 1001;

  position: sticky;
  top: 0;
  left: 0;
  width: 100%;

  transition: background-color 0.25s ease-in;
`

const Content = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  position: relative;

  @media (min-width: 800px) {
    justify-content: flex-start;
    padding: 18px 0;
  }
`

const NavDesktop = styled.nav`
  display: none;

  @media (min-width: 800px) {
    display: block;
  }
`

const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  @media (min-width: 800px) {
    flex-direction: row;
  }
`

const NavItem = styled.li`
  @media (min-width: 800px) {
    margin-right: 50px;
  }
`

const Logo = styled(require('src/images/logos/logo.svg'))`
  height: 28px;
  width: auto;

  @media (min-width: 800px) {
    height: 50px;
    margin: 0 120px 0 0;
  }
`

const TapTarget = styled.button`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  height: 48px;
  width: 48px;

  &:focus {
    outline: none;
  }
`

const NavToggle = styled(TapTarget)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  justify-content: flex-end;

  @media (min-width: 800px) {
    display: none;
  }
`

const HamburgerIcon = styled(require('src/images/icons/hamburger.svg'))`
  z-index: 1002;
`

const CloseIcon = styled(require('src/images/icons/close.svg'))`
  z-index: 1002;
`

const defaultMenuItems = [
  { name: 'Our approach', uri: '/our-approach' },
  { name: 'Our services', uri: '/our-services' },
  { name: 'Knowledge nest', uri: '/knowledge-nest' },
  { name: "Let's connect", uri: '/lets-connect' },
]

export default function Header({ menuItems = defaultMenuItems, sticky }) {
  const [open, setOpen] = useState(false)
  const isDesktop = useMediaQuery('(min-width: 800px)')

  const {
    headerMenu,
  } = useStaticQuery<GatsbyTypes.GetHeaderNavigationQuery>(graphql`
    query GetHeaderNavigation {
      headerMenu: wpMenu(locations: { eq: GATSBY_HEADER_MENU }) {
        id
        databaseId
        name
        menuItems {
          nodes {
            id
            parentId
            label
            url
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (isDesktop) setOpen(false)
  }, [isDesktop])

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.code === 'Escape') setOpen(false)
    }

    if (open) {
      document.addEventListener('keydown', handleEscape)
    } else {
      document.removeEventListener('keydown', handleEscape)
    }

    return () => document.removeEventListener('keydown', handleEscape)
  }, [open])

  useEffect(() => {
    globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        setOpen(false)
      }
    })
  }, [setOpen])

  return (
    <>
      <Container $opaque={sticky}>
        <Grid>
          <Content>
            <UnstyledLink to="/" aria-label="Navigate to Home Page">
              <Logo />
            </UnstyledLink>
            <NavToggle
              aria-label="Open Navigation Menu"
              onClick={() => setOpen(prevState => !prevState)}
            >
              {open ? <CloseIcon /> : <HamburgerIcon />}
            </NavToggle>
            <NavDesktop>
              <NavList>
                {headerMenu?.menuItems?.nodes
                  ?.filter(item => !item.parentId)
                  .map((item, index) => (
                    <NavItem key={index}>
                      <HeaderLink
                        to={item.url}
                        activeClassName="active"
                        partiallyActive={true}
                      >
                        <Heading
                          $preset="h6"
                          as="span"
                          $colour={{
                            desktop: 'var(--white)',
                            mobile: 'var(--white)',
                          }}
                        >
                          {item.label}
                        </Heading>
                      </HeaderLink>
                    </NavItem>
                  ))}
              </NavList>
            </NavDesktop>
          </Content>
        </Grid>
      </Container>
      <AnimatePresence>
        {open && (
          <NavDrawer
            menuItems={headerMenu?.menuItems?.nodes?.filter(
              item => !item.parentId
            )}
          />
        )}
      </AnimatePresence>
    </>
  )
}
