import { css } from 'styled-components'
import { vwRatio, maxRatio } from 'src/lib/styles'

export const sizeH1 = css`
  --font-size: clamp(36px, ${72 * vwRatio}vw, ${72 * maxRatio}px);
  --line-height: clamp(38px, ${76 * vwRatio}vw, ${76 * maxRatio}px);
  font-size: var(--font-size);
  line-height: var(--line-height);
`

export const sizeH2 = css`
  --font-size: clamp(32px, ${60 * vwRatio}vw, ${60 * maxRatio}px);
  --line-height: clamp(36px, ${64 * vwRatio}vw, ${64 * maxRatio}px);
  font-size: var(--font-size);
  line-height: var(--line-height);
`

export const sizeH3 = css`
  --font-size: clamp(24px, ${48 * vwRatio}vw, ${48 * maxRatio}px);
  --line-height: clamp(28px, ${52 * vwRatio}vw, ${52 * maxRatio}px);
  font-size: var(--font-size);
  line-height: var(--line-height);
`

export const sizeH4 = css`
  --font-size: clamp(21px, ${36 * vwRatio}vw, ${36 * maxRatio}px);
  --line-height: clamp(24px, ${42 * vwRatio}vw, ${42 * maxRatio}px);
  font-size: var(--font-size);
  line-height: var(--line-height);
`

export const sizeH5 = css`
  --font-size: clamp(18px, ${24 * vwRatio}vw, ${24 * maxRatio}px);
  --line-height: clamp(21px, ${28 * vwRatio}vw, ${28 * maxRatio}px);
  font-size: var(--font-size);
  line-height: var(--line-height);
`

export const sizeH6 = css`
  --font-size: clamp(16px, ${18 * vwRatio}vw, ${18 * maxRatio}px);
  --line-height: clamp(21px, ${21 * vwRatio}vw, ${21 * maxRatio}px);
  font-size: var(--font-size);
  line-height: var(--line-height);
`

export const sizeBodyLarge = css`
  --font-size: clamp(16px, ${24 * vwRatio}vw, ${24 * maxRatio}px);
  --line-height: clamp(21px, ${28 * vwRatio}vw, ${28 * maxRatio}px);
  font-size: var(--font-size);
  line-height: var(--line-height);
`

export const sizeBodyOne = css`
  --font-size: clamp(16px, ${16 * vwRatio}vw, ${16 * maxRatio}px);
  --line-height: clamp(21px, ${21 * vwRatio}vw, ${21 * maxRatio}px);
  font-size: var(--font-size);
  line-height: var(--line-height);
`

export const sizeBodyTwo = css`
  --font-size: clamp(14px, ${14 * vwRatio}vw, ${14 * maxRatio}px);
  --line-height: clamp(21px, ${21 * vwRatio}vw, ${21 * maxRatio}px);
  font-size: var(--font-size);
  line-height: var(--line-height);
`
