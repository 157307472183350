import React from 'react'
import { graphql } from 'gatsby'
import { useInView } from 'react-intersection-observer'
import useMediaQuery from 'src/hooks/useMediaQuery'
import styled from 'styled-components'
import { NormalizeStyles, GlobalStyles } from 'src/styles'
import { Header, Footer, FooterCTA } from 'src/components/Layout'
import { CEO } from 'src/components/Elements'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* min-width: 375px; */
`

const Content = styled.main`
  flex-grow: 1;
  /* overflow: hidden; */
  /* overflow-x: hidden; */
  /* overflow-y: visible; */

  margin: ${({ hasCTA }) =>
    hasCTA.mobile
      ? '-64px 0 -200px'
      : '-64px 0 clamp(-90px, -4.6875vw, -18px)'};

  @media (min-width: 800px) {
    margin: ${({ hasCTA }) =>
      hasCTA.desktop
        ? '-90px 0 clamp(-270px, -14.0625vw, -180px)'
        : '-90px 0 clamp(-90px, -4.6875vw, -18px)'};
  }
`

const Top = styled.div`
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  /* position: absolute; */
`

export interface WrapperProps {
  children: React.ReactNode
}

/**
 * Layout Wrapper with global & normalized styling
 */
export const Wrapper: React.VFC<WrapperProps> = ({ data, children }) => {
  const postType = data && Object.keys(data)[0]

  const hasFooterCTA =
    data && data[postType].ACF?.options?.footerStyle === 'cta'
  const hasMobileEmail =
    data && data[postType].ACF?.options?.footerStyle === 'mobileEmail'

  const { ref, inView } = useInView()

  const isDesktop = useMediaQuery('(min-width: 800px)')

  return (
    <>
      <NormalizeStyles />
      <GlobalStyles />
      <Container>
        <div id="top-of-document" />
        <Header sticky={!inView} />
        <Content
          hasCTA={{
            desktop: hasFooterCTA,
            mobile: hasFooterCTA || hasMobileEmail,
          }}
        >
          <Top ref={ref} />
          {children}
          {hasFooterCTA && <FooterCTA />}
          {hasMobileEmail && !isDesktop && (
            <section
              css={`
                margin: -100px 0 0;
                padding: 0 40px;
              `}
            >
              <CEO
                css={`
                  margin: 0;
                `}
              />
            </section>
          )}
        </Content>
        <Footer
          hasCTA={{
            desktop: hasFooterCTA,
            mobile: hasFooterCTA || hasMobileEmail,
          }}
        />
      </Container>
    </>
  )
}

export default Wrapper

export const Options = graphql`
  fragment OptionsFields on WpContent_Acf {
    options {
      footerStyle
    }
  }
`
