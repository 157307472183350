import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'

interface HeadProps {
  meta: {
    title: string
    description: string
    media: {
      altText: string
      mediaType: string
      mimeType: string
      localFile: {
        publicURL: string
      }
    }
  }
  page: {
    __typename: 'WpPage' | 'WpPost' | 'WpCaseStudy'
    title: string
    uri: string
  }
}

export default function Head({ meta, page }: HeadProps) {
  const {
    wp: { generalSettings },
  } = useStaticQuery<GatsbyTypes.GetGeneralSettingsQuery>(graphql`
    query GetGeneralSettings {
      wp {
        generalSettings {
          title
          description
          language
        }
      }
    }
  `)

  /**
   * if meta title exists, format will show:
   * "meta title - page title | site title"
   * "Lorem ipsum dolor isset - Sample Page | Colloqui Interactive"
   *
   * if meta title doesn't exist, format will show:
   * "page title | site title"
   * "Sample Page | Colloqui Interactive"
   */
  const title = `${
    meta?.title
      ? `${meta.title}${page.title === 'Home' ? `` : ` - ${page.title}`}`
      : page.title
  } | ${generalSettings.title}`

  /**
   *
   */
  const pageUrl = page.uri
    ? `${process.env.GATSBY_SITE_URL}${page.uri}`
    : `${process.env.GATSBY_SITE_URL}`

  const mediaUrl = meta?.media?.localFile.publicURL
    ? `${process.env.GATSBY_SITE_URL}${meta?.media?.localFile.publicURL}`
    : `${process.env.GATSBY_SITE_URL}/images/favicon.png`

  return (
    <Helmet>
      <html lang={generalSettings.language === 'en_US' ? 'en-US' : 'en'} />
      <link rel="canonical" href={process.env.GATSBY_SITE_URL} />
      <title lang={generalSettings.language === 'en_US' ? 'en-US' : 'en'}>
        {title}
      </title>
      <meta
        name="description"
        content={meta?.description || generalSettings.description}
      />
      <meta name="image" content={mediaUrl} />
      {/* Open Graph */}
      <meta property="og:title" content={title} />
      <meta
        property="og:description"
        content={meta?.description || generalSettings.description}
      />
      <meta property="og:url" content={pageUrl} />
      <meta
        property={`og:${meta?.media?.mediaType === 'test' ? 'video' : 'image'}`}
        content={mediaUrl}
      />
      <meta property="og:type" content="website" />
      {/* change to article for blog posts */}
      <meta name="twitter:card" content="summary" />
      {/* change to embedded player for video series */}
      <meta name="twitter:title" content={title} />
      <meta
        name="twitter:description"
        content={meta?.description || generalSettings.description}
      />
      <meta name="twitter:url" content={pageUrl} />
      <meta name="twitter:image" content={mediaUrl} />
      <meta name="twitter:player" content={mediaUrl} />
    </Helmet>
  )
}

export const fragment = graphql`
  fragment MetaFields on WpContent_Acf {
    meta {
      title
      description
      media {
        altText
        mediaType
        mimeType
        localFile {
          publicURL
        }
      }
    }
  }
`
