import React from 'react'
import styled from 'styled-components'
import { backgroundColour } from 'src/lib/styles'
import type { Colour } from 'src/lib/styles'

const Section = styled.section<{ $type?: string; $zIndex?: number }>`
  margin: ${({ $type }) => {
    switch ($type) {
      case 'flat':
        return '0'
      case 'round':
        return '0 0 clamp(-23px, -1.171875vw, -5px)'
      case 'curve-up':
        return '0 0 clamp(-90px, -4.6875vw, -18px)'
      case 'curve-down':
        return '0 0 clamp(-90px, -4.6875vw, -18px)'
      case 'wave':
        return '0 0 clamp(-72px, -3.75vw, -14px)'
      default:
        throw new Error(`[Error] Unhandled divider type ${$type}`)
    }
  }};
  position: relative;
  z-index: ${({ $zIndex }) => ($zIndex ? $zIndex + 1 : 10)};

  &:last-child {
    margin: 0;
  }
`

const Above = styled.div<{
  $bgColour?: Colour
  $type?: string
}>`
  ${backgroundColour}

  height: ${({ $type }) => {
    switch ($type) {
      case 'flat':
        return '0'
      case 'round':
        return 'clamp(9px, 2.34375vw, 45px)'
      case 'curve-up':
        return 'clamp(22px, 5.859375vw, 113px)'
      case 'curve-down':
        return 'clamp(22px, 5.859375vw, 113px)'
      case 'wave':
        return 'clamp(18px, 4.6875vw, 90px)'
      default:
        throw new Error('[Error] Unhandled divider type')
    }
  }};
  width: 100%;
  clip-path: ${({ $type }) => {
    switch ($type) {
      case 'flat':
        return
      case 'round':
        return 'url(#round-clip)'
      case 'curve-up':
        return 'url(#curve-up-clip)'
      case 'curve-down':
        return 'url(#curve-down-clip)'
      case 'wave':
        return 'url(#wave-clip)'
      default:
        throw new Error('[Error] Unhandled divider type')
    }
  }};
`

const RoundClipSvg = styled(require('src/images/shapes/round-clip.svg'))`
  position: absolute;
  height: 0;
  width: 0;
`
const CurveUpClipSvg = styled(require('src/images/shapes/curve-up-clip.svg'))`
  position: absolute;
  height: 0;
  width: 0;
`

const CurveDownClipSvg = styled(
  require('src/images/shapes/curve-down-clip.svg')
)`
  position: absolute;
  height: 0;
  width: 0;
`

const WaveClipSvg = styled(require('src/images/shapes/wave-clip.svg'))`
  position: absolute;
  height: 0;
  width: 0;
`

interface DividerProps {
  bgColour?: Colour
  type?: string
  zIndex?: number
}

function getSvg(type?: string) {
  switch (type) {
    case 'flat':
      return null
    case 'round':
      return <RoundClipSvg />
    case 'curve-up':
      return <CurveUpClipSvg />
    case 'curve-down':
      return <CurveDownClipSvg />
    case 'wave':
      return <WaveClipSvg />
    default:
      throw new Error('[Error] Unhandled divider type')
  }
}

export default function Divider({ type, bgColour, zIndex }: DividerProps) {
  return (
    <Section $type={type} $zIndex={zIndex}>
      {getSvg(type)}
      <Above $bgColour={bgColour} $type={type} />
    </Section>
  )
}
