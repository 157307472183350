import { css } from 'styled-components'

type Direction = string | undefined

type BorderRadiusRow = {
  direction?: ReadonlyArray<Direction>
  unit?: string
  value?: number
}

export type BorderRadius = {
  desktop?: ReadonlyArray<BorderRadiusRow | undefined>
  mobile?: ReadonlyArray<BorderRadiusRow | undefined>
}

export const borderRadius = css<{ $borderRadius?: BorderRadius }>`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  ${({ $borderRadius }) =>
    Array.isArray($borderRadius?.mobile)
      ? $borderRadius?.mobile.map(row =>
          row.direction.map(
            (dir: Direction) =>
              dir &&
              `border-${dir
                .split(/(?=[A-Z])/)
                .map((s: string) => s.toLowerCase())
                .join('-')}-radius: ${row.value}${row.unit}; `
          )
        )
      : 'border-radius: 0;'}

  @media (min-width: 800px) {
    ${({ $borderRadius }) =>
      Array.isArray($borderRadius?.desktop)
        ? $borderRadius?.desktop.map(row =>
            row.direction.map(
              (dir: Direction) =>
                dir &&
                `border-${dir
                  .split(/(?=[A-Z])/)
                  .map((s: string) => s.toLowerCase())
                  .join('-')}-radius: ${row.value}${row.unit}; `
            )
          )
        : 'border-radius: 0;'}
  }
`
