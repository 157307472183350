import { css } from 'styled-components'

type Direction = string | undefined

type PaddingRow = {
  direction?: ReadonlyArray<Direction>
  unit?: string
  value?: number
}

export type Padding = {
  desktop?: ReadonlyArray<PaddingRow | undefined>
  mobile?: ReadonlyArray<PaddingRow | undefined>
}

export const padding = css<{ $padding?: Padding }>`
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;

  ${({ $padding }) =>
    Array.isArray($padding?.mobile)
      ? $padding?.mobile?.map(row =>
          row?.direction?.map(
            (dir: Direction) =>
              dir && `padding-${dir}: ${row.value}${row.unit}; `
          )
        )
      : 'padding: 0;'}

  @media (min-width: 800px) {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;

    ${({ $padding }) =>
      Array.isArray($padding?.desktop)
        ? $padding?.desktop?.map(row =>
            row?.direction?.map(
              (dir: Direction) =>
                dir && `padding-${dir}: ${row.value}${row.unit}; `
            )
          )
        : 'padding: 0;'}
  }
`
