import React from 'react'
import { graphql } from 'gatsby'
import {
  GatsbyImage,
  GatsbyImageProps,
  withArtDirection,
} from 'gatsby-plugin-image'
import styled from 'styled-components'
import { borderRadius, margin } from 'src/lib/styles'
import type { BorderRadius, Margin } from 'src/lib/styles'
import { fontMedium, sizeBodyTwo } from 'src/lib/typography'

export type Source = {
  desktop?: GatsbyTypes.DesktopImageFieldsFragment
  mobile?: GatsbyTypes.MobileImageFieldsFragment
}

interface ImageProps {
  source?: Source
  query?: string
  [x: string]: any
}

const BackgroundContainer = styled.div`
  display: block;
  /* height: auto;
  width: 100%; */
  object-fit: cover;
  z-index: 0;

  ${({ $cover, $imageAspectRatio, $containerAspectRatio }) => {
    if ($cover) {
      // Should the background image cover the container?
      if ($imageAspectRatio.mobile < $containerAspectRatio.mobile) {
        // if the container aspect ratio is wider than the image,
        // set the height to 100% so the width overflows
        return `
          height: 100%;
          aspect-ratio: ${1 / $imageAspectRatio.mobile};


          & .gatsby-image-wrapper {
            height: 100%;
            aspect-ratio: ${1 / $imageAspectRatio.mobile};
          }
        `
      } else {
        // if the container aspect ratio is narrower than the image,
        // set the width to 100% so the height overflows
        return `
          height: auto;
          width: 100%;
        `
      }
    } else {
      return `
        height: auto;  
        width: 100%;
      `
    }
  }};

  @media (min-width: 800px) {
    ${({ $cover, $imageAspectRatio, $containerAspectRatio }) => {
      if ($cover) {
        if ($imageAspectRatio.desktop < $containerAspectRatio.desktop) {
          return `
          height: 100%;
          aspect-ratio: ${1 / $imageAspectRatio.desktop};

          & .gatsby-image-wrapper {
            height: 100%;
            aspect-ratio: ${1 / $imageAspectRatio.desktop};
          }
        `
        } else {
          return `
            height: auto;
            width: 100%;
          `
        }
      } else {
        return `
          height: auto;  
          width: 100%;
        `
      }
    }};
  }
`

const BackgroundFilter = styled.div`
  background-color: var(--purple900);
  opacity: 0.6;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

interface CmsImageContainerProps {
  $margin?: Margin
  $borderRadius?: BorderRadius
}

const CmsImageContainer = styled.div<CmsImageContainerProps>`
  ${margin}

  .gatsby-image-wrapper,
  .gatsby-image-wrapper img {
    ${borderRadius}
  }
`

interface StyledImageProps extends GatsbyImageProps {
  $aspectRatio?: {
    desktop?: number
    mobile?: number
  }
  $query?: string
}

const StyledImage = styled(GatsbyImage)<StyledImageProps>`
  flex: 1;

  & [aria-hidden='true'] {
    padding-top: ${({ $aspectRatio }) =>
      $aspectRatio?.mobile
        ? `${$aspectRatio?.mobile * 100}% !imporant`
        : $aspectRatio?.desktop
        ? `${$aspectRatio?.desktop * 100}% !important`
        : null};
  }
  ${({ $query, $aspectRatio }) =>
    $aspectRatio?.desktop
      ? `@media ${$query} {
      & [aria-hidden="true"] {
        padding-top: ${$aspectRatio?.desktop * 100}% !important;
      }
    }
  `
      : null}
`

const Figure = styled.figure`
  margin: 0;
`

export const MobileCaption = styled.figcaption`
  ${fontMedium}
  ${sizeBodyTwo}
  margin: 12px 0 0;

  display: block;

  p {
    margin: 0;
  }

  ${({ $query }) => `@media ${$query || '(min-width: 800px)'} {
    display: none;
  }
  `}
`

export const DesktopCaption = styled.figcaption`
  ${fontMedium}
  ${sizeBodyTwo}
  margin: 12px 0 0;

  p {
    margin: 0;
  }

  display: none;
  ${({ $query }) => `@media ${$query || '(min-width: 800px)'} {
    display: block;
  }
  `}
`

StyledImage.defaultProps = {
  $query: '(min-width: 800px)',
}

export function Image({ source, query, caption = true, ...props }: ImageProps) {
  const mobileImage =
    source?.mobile?.localFile?.childImageSharp?.gatsbyImageData
  const desktopImage =
    source?.desktop?.localFile?.childImageSharp?.gatsbyImageData

  const sources =
    mobileImage && desktopImage
      ? withArtDirection(mobileImage, [
          { media: '(min-width: 800px)', image: desktopImage },
        ])
      : desktopImage

  const hasCaption = source?.mobile?.caption || source?.desktop?.caption

  return (
    <Figure>
      <StyledImage
        image={sources}
        alt={source?.mobile?.altText || source?.desktop?.altText || ''}
        $query={query}
        $aspectRatio={{
          mobile: mobileImage?.height / mobileImage?.width,
          desktop: desktopImage?.height / desktopImage?.width,
        }}
        {...props}
      />
      {caption && hasCaption && (
        <figcaption>
          <MobileCaption
            $query={query}
            dangerouslySetInnerHTML={{
              __html: source?.mobile?.caption || source?.desktop.caption,
            }}
          />
          <DesktopCaption
            $query={query}
            dangerouslySetInnerHTML={{ __html: source?.desktop?.caption }}
          />
        </figcaption>
      )}
    </Figure>
  )
}

interface BackgroundImageProps {
  image?: {
    desktop?: GatsbyTypes.DesktopImageFieldsFragment
    mobile?: GatsbyTypes.MobileImageFieldsFragment
  }
  imgProps?: any
  container?: {
    aspectRatio?: {
      desktop?: number
      mobile?: number
    }
  }
  hasFilter?: boolean
  cover?: boolean
}

export function BackgroundImage({
  image,
  imgProps,
  hasFilter,
  cover,
  container,
}: BackgroundImageProps) {
  const imageAspectRatio = {
    mobile:
      image?.mobile?.localFile?.childImageSharp?.gatsbyImageData.height /
      image?.mobile?.localFile?.childImageSharp?.gatsbyImageData.width,
    desktop:
      image?.desktop?.localFile?.childImageSharp?.gatsbyImageData.height /
      image?.desktop?.localFile?.childImageSharp?.gatsbyImageData.width,
  }

  return (
    <BackgroundContainer
      $cover={cover}
      $imageAspectRatio={imageAspectRatio}
      $containerAspectRatio={container?.aspectRatio}
    >
      <Image source={image} {...imgProps} />
      {hasFilter && <BackgroundFilter />}
    </BackgroundContainer>
  )
}

interface CmsImageProps {
  data?: GatsbyTypes.ImageFieldsFragment
}

export function CmsImage({ data }: CmsImageProps) {
  return (
    <CmsImageContainer
      $borderRadius={data?.borderRadius}
      $margin={data?.margin}
    >
      <Image source={data?.image} />
    </CmsImageContainer>
  )
}

export const desktopImageFragment = graphql`
  fragment DesktopImageFields on WpMediaItem {
    altText
    caption
    localFile {
      childImageSharp {
        gatsbyImageData(
          backgroundColor: "#fefefe"
          layout: FULL_WIDTH
          placeholder: NONE
          quality: 90
        )
      }
    }
  }
`

export const mobileImageFragment = graphql`
  fragment MobileImageFields on WpMediaItem {
    altText
    caption
    localFile {
      childImageSharp {
        gatsbyImageData(
          backgroundColor: "#fefefe"
          layout: FULL_WIDTH
          placeholder: NONE
          quality: 90
        )
      }
    }
  }
`

export const fragment = graphql`
  fragment ImageFields on WpContent_Acf_Layouts_Columns_columns_Elements_Image {
    image {
      desktop {
        ...DesktopImageFields
      }
      mobile {
        ...MobileImageFields
      }
    }
    borderRadius {
      desktop {
        direction
        unit
        value
      }
      mobile {
        direction
        unit
        value
      }
    }
    margin {
      desktop {
        direction
        unit
        value
      }
      mobile {
        direction
        unit
        value
      }
    }
  }
`
