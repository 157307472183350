import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { ColumnGrid } from 'src/components/Layout'
import { NavAccordion } from 'src/components/Navigation'
import { LinkStyledAsText } from 'src/components/Elements'
import { fontRoman } from 'src/lib/typography'

const Container = styled.footer`
  background-color: var(--purple900);
  padding: ${({ $hasCTA }) => ($hasCTA.mobile ? '240px 0 48px' : '138px 0')};

  @media (min-width: 1280px) {
    padding: ${({ $hasCTA }) =>
      $hasCTA.desktop ? 'clamp(240px, 18.75vw, 360px) 0 60px' : '150px 0 60px'};
  }
`

const LeftColumn = styled.div`
  grid-column: 1 / -1;
  grid-row: 2 / 3;

  margin: 48px 0 0;

  @media (min-width: 1280px) {
    grid-column: 2 / 6;
    grid-row: 1 / 2;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
  }
`

const NavColumns = styled.nav`
  grid-column: 1 / -1;
  grid-row: 1 / 2;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1280px) {
    grid-column: 6 / 12;

    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
  }
`

const NavColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 32px;
  width: 100%;

  &:last-child {
    margin: 0;
  }

  @media (min-width: 1280px) {
    align-items: flex-start;
    margin: 0 100px 0 0;
    width: auto;

    &:last-child {
      margin: 0 50px 0 0;
    }
  }
`

const LogoBird = styled(require('src/images/logos/colloqui-bird.svg'))`
  display: none;
  margin-bottom: 32px;

  @media (min-width: 1280px) {
    display: block;
  }
`

const CopyrightText = styled.span`
  color: var(--white);
  display: block;
  ${fontRoman}
  font-size: 12px;
  line-height: 21px;
  margin: 0 auto;
  max-width: 310px;
  text-align: center;

  @media (min-width: 1280px) {
    font-size: clamp(12px, 0.9375vw, 18px);
    line-height: clamp(21px, 1.640625vw, 32px);
    max-width: clamp(345px, 26.953125vw, 518px);
    margin: 0;
    text-align: left;
  }
`

export default function Footer({ hasCTA }) {
  const {
    footerMenu,
  } = useStaticQuery<GatsbyTypes.GetFooterNavigationQuery>(graphql`
    query GetFooterNavigation {
      footerMenu: wpMenu(locations: { eq: GATSBY_FOOTER_MENU }) {
        id
        databaseId
        name
        menuItems {
          nodes {
            id
            parentId
            label
            url
            menuIcons {
              icon {
                altText
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Container $hasCTA={hasCTA}>
        <ColumnGrid>
          <LeftColumn>
            <LogoBird />
            <CopyrightText>
              © 2021 Colloqui: the interactive company™ | Colloqui™ word marks
              and logos are trademarks owned by Colloqui: the interactive
              company |{' '}
              <LinkStyledAsText to="/privacy">Privacy Policy</LinkStyledAsText>
            </CopyrightText>
          </LeftColumn>
          <NavColumns>
            {footerMenu?.menuItems?.nodes
              ?.filter(item => !item.parentId)
              .map(item => (
                <NavColumn key={item.id}>
                  <NavAccordion
                    heading={item.label}
                    url={item.url}
                    links={footerMenu.menuItems?.nodes?.filter(
                      i => i.parentId === item.id
                    )}
                  />
                </NavColumn>
              ))}
          </NavColumns>
        </ColumnGrid>
      </Container>
    </>
  )
}
